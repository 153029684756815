import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const login = async (e: any, username: string, password: string) => {
    e.preventDefault();
    try {
      const response = await fetch("https://openaiapi.yfzcapital.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          username: username,
          password: password,
        }),
      });

      if (response.ok) {
        const token = response.headers.get('Authorization')
        if (token) {
          localStorage.setItem('token', token);
        }

        // const data = await response.json();
        // if (data.token) {
        //   localStorage.setItem("token", data.token);
        // } else {
        //   throw new Error("token not found in response");
        // }
        navigate("/");
      } else {
        throw new Error("Login failed.");
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h1 style={{ textAlign: "center" }}>Login</h1>
      <form
        style={{ textAlign: "center" }}
        onSubmit={(e) => login(e, username, password)}
      >
        <span>
          <label htmlFor="username">Username</label>
          <input
            style={{ margin: "10px", width: "200px" }}
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </span>
        <br />
        <span>
          <label htmlFor="password">Password</label>
          <input
            style={{ margin: "10px", width: "200px" }}
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </span>
        <br />
        <button style={{ margin: "10px" }} type="submit">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
